//
// breadcrumb
//

.breadcrumb {
  display: inline-flex;
  background: transparent;
}

.breadcrumb-item {

  + .breadcrumb-item {
    margin-left: rem(16);

    &::before {
      margin-right: rem(16);
    }
  }

  a {
    color: inherit;
  }
}