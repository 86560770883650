//
// spacing
//


// gutter
$gutter-list: ();
$gutter-list: map-merge((
  0: 0,
  1: 8,
  2: 16,
  3: 24,
  4: 32,
  5: 40,
  6: 48,
  7: 56,
  8: 64

), $gutter-list);

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $name, $value in $gutter-list {
      .gutter#{$infix}-#{$name} {
        margin: -#{$value/2}px;

        > * {
          margin-bottom: 0!important;
          padding: #{$value/2}px;
        }
      }
    }

  }
}