// Custom Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// typography
$font-size-list:           ( 16, 20, 24, 28, 32, 36, 40, 44, 48, 52, 56, 60, 64, 68, 72, 76, 80, 84, 88, 92 );


// layout
$section-padding:            10rem; // 160px
$section-padding-md:         7.5rem; // 120px
$section-padding-sm:         5rem; // 80px


// inverted
$inverted-border:           rgba($white, .25);


// social colors
$facebook:                 #3b5998;
$google-plus:              #dd4b39;
$twitter:                  #00aced;
$linkedin:                 #007bb6;
$pinterest:                #cb2027;
$git:                      #666666;
$tumblr:                   #32506d;
$vimeo:                    #aad450;
$youtube:                  #bb0000;
$flickr:                   #ff0084;
$reddit:                   #ff4500;
$dribbble:                 #ea4c89;
$skype:                    #00aff0;
$instagram:                #517fa4;
$behance:                  #1769ff;
$rss:                      #f26522;

$socials: ();
$socials: map-merge((
  "facebook": $facebook,
  "google-plus": $google-plus,
  "twitter": $twitter,
  "linkedin": $linkedin,
  "pinterest": $pinterest,
  "git": $git,
  "tumblr": $tumblr,
  "vimeo": $vimeo,
  "flickr": $flickr,
  "reddit": $reddit,
  "dribbble": $dribbble,
  "skype": $skype,
  "instagram": $instagram,
  "behance": $behance,
  "rss": $rss,
  "youtube": $youtube
), $socials);