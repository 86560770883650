//
// logo
//


.logo {
	text-align: center;

	img {
		display: inline-block!important;
		height: 100%;
		max-height: rem(40);
		max-width: rem(160);
	}

	&[class*="-sm"] img {
		max-height: rem(32);
		max-width: rem(104);
	}
}